import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`During my internship in Luidia, I created some experiments using eBeam technology. In Live Map I tried to create a map based on live feed videos. I made three maps of the same spot and then over lap them on top of each other. Here is the result:
`}<img parentName="p" {...{
        "src": "http://raschin.com/blog/wp-content/uploads/2010/10/livemap.jpg",
        "alt": "live_map"
      }}></img></p>
    <p>{`I projected tiles of live streams on the wall and draw the map on top of that with eBeam.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=m0U9SqzaTQ0&feature=emb_logo"
      }}>{`Watch Ivemap demo in CCA`}</a></p>
    <p>{`These are some other projects that I did in collaboration with other interns.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=BF_hz3iZ1go&feature=emb_logo"
      }}>{`Watch Digipucks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=fvH2f5QPD2Y&feature=emb_logo"
      }}>{`Watch post it frames`}</a></p>
    <p>{`2020 by Raschin.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      